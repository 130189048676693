<template>
  <layout-sub name="intro">
    <base-horizontal-card
      :img="require('@/assets/img-intro.jpg')"
      :detail="content"
      border-variant="white"
      img-right
      class="p-intro"
    />
  </layout-sub>
</template>

<script>
import LayoutSub from "@/components/LayoutSub";
import BaseHorizontalCard from "@/components/BaseHorizontalCard";
import { fatchCurrent } from "@/api";

export default {
  name: "Intro",
  components: { LayoutSub, BaseHorizontalCard },
  data() {
    return {
      content: "",
    };
  },
  mounted() {
    // 获取当前页面内容
    this.init();
  },
  methods: {
    async init() {
      const { data } = await fatchCurrent(21);
      if (data) {
        const { content } = data;
        this.content = content;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.p-intro {
  font-size: $font-size-lg;
}
</style>
